import {
	chakra,
	ChakraProps,
	useBreakpointValue,
	useMultiStyleConfig,
	VisuallyHidden,
} from "@chakra-ui/react";
import React from "react";
import { ResponsiveImage } from "../../__generated__/types/graphql-codegen";
import { CMSImage } from "../CMSImage";
import { Hx } from "../headings";

type Props = ChakraProps & {
	kicker?: string;
	title?: string;
	image: ResponsiveImage;
};

const desktopBreakpoint = "lg";

export const FadeHeroSection: ReactFC<Props> = ({
	title,
	kicker,
	image,
	...rest
}) => {
	const styles = useMultiStyleConfig("FadeHeroSection", {
		desktopBreakpoint,
	});

	// These are inline styles, there's no better way
	const pictureStyle = useBreakpointValue(
		{
			base: {
				width: "100%",
				height: "100%",
				objectFit: "cover",
			} as const,
			[desktopBreakpoint]: undefined,
		},
		{ fallback: desktopBreakpoint },
	);

	const layout = useBreakpointValue(
		{
			base: "fill" as const,
			[desktopBreakpoint]: undefined,
		},
		{ fallback: desktopBreakpoint },
	);

	return (
		<chakra.div {...rest} __css={styles.container}>
			<chakra.div __css={styles.imageContainer}>
				<CMSImage
					pictureStyle={pictureStyle}
					layout={layout}
					responsiveImage={image}
					alt={image.alt ?? ""}
				/>
			</chakra.div>
			<chakra.div __css={styles.content}>
				<Hx sx={styles.heading}>
					<chakra.span __css={styles.kicker}>{kicker}</chakra.span>
					<VisuallyHidden>—</VisuallyHidden>
					{title}
				</Hx>
			</chakra.div>
		</chakra.div>
	);
};
