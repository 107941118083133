import { createElement, ReactElement, Fragment } from "react";

export const replacePipeWithBr = (str?: string): ReactElement => {
	if (str?.includes(" | ")) {
		const parts = str
			.split(" | ")
			.map((p) => [p, createElement("br", { key: p })])
			.flat()
			.slice(0, -1);

		return createElement(Fragment, {}, parts);
	}

	return createElement(Fragment, {}, str);
};
