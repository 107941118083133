import { ChakraProps } from "@chakra-ui/react";
import React from "react";
import { useBrandVariantName } from "../../hooks";
import {
	AdvantageItem,
	AdvantageItemContent,
	AdvantageItemTitle,
	Advantages,
} from "./Advantages";

export type HomeAdvantagesSectionType = ChakraProps & {
	title?: string;
	items?: Array<{
		id: string;
		title?: string;
		content?: string;
		icon?: string;
	}>;
};

const getHtmlFromContent = (content: string) =>
	content.includes("<p>") ? content : `<p>${content}</p>`;

export const HomeAdvantagesSection: ReactFC<HomeAdvantagesSectionType> = ({
	title,
	items,
	...rest
}) => {
	const variant = useBrandVariantName();

	return items ? (
		<Advantages title={title} variant={variant} {...rest}>
			{items.map((item) => (
				<AdvantageItem key={item.id}>
					<AdvantageItemTitle icon={item.icon}>
						{item.title}
					</AdvantageItemTitle>
					{item.content && (
						<AdvantageItemContent
							html={getHtmlFromContent(item.content)}
						/>
					)}
				</AdvantageItem>
			))}
		</Advantages>
	) : null;
};
