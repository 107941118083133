import { Divider, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { HomeAdvantagesSection } from "../../../components/Advantages/HomeAdvantagesSection";
import { BriefingSection } from "../../../components/BriefingSection";
import { FadeHeroSection } from "../../../components/FadeHeroSection/FadeHeroSection";
import { HeadingLevelBoundary } from "../../../components/headings";
import { HomePageHeader } from "../../../components/HomePageHeader/HomePageHeader";
import { HomeNewsletterSection } from "../../../components/Newsletter/HomeNewsletterSection";
import { NewsletterContentConfig } from "../../../components/Newsletter/NewsletterForm";
import { ArticleQuartetSection } from "../../../components/teasers/TeaserQuartet";
import { replacePipeWithBr } from "../../../utils/replacePipeWithBr";
import { useHomeSectionLayoutWithArticleTeasers } from "./helpers";
import { HomePageProps, NewsletterSection } from "./HomePage";

export const HomePageSectionRenderer: React.FC<
	Pick<HomePageProps, "layout" | "teasers">
> = ({ teasers, layout }) => {
	const sectionLayout = useHomeSectionLayoutWithArticleTeasers(
		teasers,
		layout,
	);
	const allStyles = useMultiStyleConfig("HomeSections", {});

	return (
		<HeadingLevelBoundary>
			{sectionLayout.map((section) => {
				const common =
					"bottomDivider" in section && section.bottomDivider ? (
						<Divider variant={"solid"} mt={12} />
					) : null;

				const styles = allStyles[section.__typename];

				switch (section.__typename) {
					case "SectionHomeHeaderTitleRecord": {
						const title = replacePipeWithBr(section.title ?? "");

						return section.title ? (
							<HomePageHeader key={section.id}>
								{title}
							</HomePageHeader>
						) : null;
					}
					case "SectionsNewsletterRecord": {
						return section.disclaimer && section.description ? (
							<HomeNewsletterSection
								key={section.id}
								disclaimer={section.disclaimer}
								description={section.description}
							/>
						) : null;
					}
					case "SectionHomeAdvantagesBlockRecord": {
						return (
							<React.Fragment key={section.id}>
								<HomeAdvantagesSection
									sx={styles}
									title={section.title ?? undefined}
									items={section.items.map((item) => ({
										title: item.title ?? undefined,
										icon: item.itemIcon ?? undefined,
										id: item.id,
										content: item.content ?? undefined,
									}))}
								/>
								{common}
							</React.Fragment>
						);
					}
					case "SectionHomeNewsletterRecord": {
						return (
							<React.Fragment key={section.id}>
								<NewsletterSection __css={styles} mt={12} />
								{common}
							</React.Fragment>
						);
					}
					case "SectionPromotedArticleQuartetRecord": {
						return (
							<React.Fragment key={section.id}>
								<ArticleQuartetSection
									mt={16}
									promotedArticles={section.promotedArticles}
								/>
								{common}
							</React.Fragment>
						);
					}
					case "SectionHomeTeaserHeroRecord":
					case "SectionHomeTeaserQuartetRecord":
					case "SectionHomeTeaserSingleRecord":
					case "SectionHomeTeaserThreeColumnsTradingviewRecord":
					case "SectionHomeTeaserTwoByTwoRecord": {
						if (section.teasers.length > 0 && section.component) {
							return (
								<React.Fragment key={section.id}>
									<section.component
										key={section.id}
										teasers={section.teasers}
										__css={styles}
										mt={12}
									/>
									{common}
								</React.Fragment>
							);
						}
						break;
					}
					case "SectionHomeBriefingRecord": {
						const newsletter: NewsletterContentConfig = {
							title: section.newsletterTitle ?? undefined,
							description:
								section.newsletterDescription ?? undefined,
							buttonLabel:
								section.newsletterButtonLabel ?? undefined,
							disclaimer:
								section.newsletterDisclaimer ?? undefined,
						};

						return (
							<React.Fragment key={section.id}>
								<BriefingSection
									newsletter={newsletter}
									heading={section.title ?? undefined}
									content={section.content ?? undefined}
									decorativeImage={
										section.image?.responsiveImage ??
										undefined
									}
									advantages={section.advantages}
								/>
								{common}
							</React.Fragment>
						);
					}
					case "SectionHomeFadeHeroRecord": {
						return (
							section.backgroundImage?.responsiveImage && (
								<FadeHeroSection
									key={section.id}
									image={
										section.backgroundImage.responsiveImage
									}
									kicker={section.kicker ?? undefined}
									title={section.title ?? undefined}
								/>
							)
						);
					}
					default: {
						return null;
					}
				}

				return null;
			})}
		</HeadingLevelBoundary>
	);
};
