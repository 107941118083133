import {
	chakra,
	ChakraProps,
	Divider,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { TextOnlyTeaser } from "./TextOnlyTeaser";
import { TeaserData } from "./utils";

export const TwoByTwoTextOnlyTeasers: ReactFC<
	ChakraProps & {
		teasers: Array<TeaserData>;
	}
> = ({ teasers, ...rest }) => {
	const styles = useMultiStyleConfig("TwoByTwoTextOnlyTeasers", {});

	return (
		<chakra.div __css={{ ...rest, ...styles.wrapper }}>
			{teasers.map((teaser) => (
				<chakra.div
					__css={styles.teaserContainer}
					key={teaser.urlParams.slug}
				>
					<TextOnlyTeaser teaser={teaser} />
					<Divider className="divider" mt={6} />
				</chakra.div>
			))}
		</chakra.div>
	);
};
