import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export const useNewsletterSignup = (
	userData: {
		email: string;
		brand: string;
		pageTitle?: string | null;
		pageUrl?: string | null;
		company?: string | null;
		sector?: string | null;
		price?: number | null;
	},
	tag: string,
	options: UseMutationOptions,
) =>
	useMutation(
		async () => {
			const body = JSON.stringify({
				...userData,
				tag,
			});

			const response = await fetch(`/api/subscribe`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body,
			});

			if (!response.ok) {
				throw new Error(
					`Essential ActiveCampaign operations have failed`,
				);
			}

			return response.json();
		},
		{
			...options,
		},
	);
