import { ComponentType, useMemo } from "react";
import { ChakraProps } from "@chakra-ui/react";
import { ThreeColumnsTradingView } from "../../../components/teasers/ThreeColumnsTradingView";
import { SingleHeroTeaser } from "../../../components/teasers/SingleHeroTeaser";
import { TeaserQuartet } from "../../../components/teasers/TeaserQuartet";
import { TwoByTwoTextOnlyTeasers } from "../../../components/teasers/TwoByTwoTextOnlyTeasers";
import { TeaserData } from "../../../components/teasers/utils";
import { BrandSettings } from "../../../lib/brandSettings";
import { sliceArray } from "../../../utils/misc";
import {
	teasersPerTeaserLayout,
	TeaserSectionType,
} from "../../../portalstack.config";
import { SingleTeaser } from "../../../components/teasers/SingleTeaser";

export type Layout = BrandSettings["layout"][number];

type TeaserSectionComponent = ComponentType<
	ChakraProps & { teasers: Array<TeaserData> }
>;

export const teaserSectionList = Object.keys(teasersPerTeaserLayout);

export const useHomeSectionLayoutWithArticleTeasers = (
	teasers: Array<TeaserData>,
	layoutRaw: Array<Layout>,
): Array<
	Layout & {
		teasers: Array<TeaserData>;
		component: TeaserSectionComponent | null;
	}
> => {
	return useMemo(() => {
		const teaserComponents: Record<
			TeaserSectionType,
			TeaserSectionComponent
		> = {
			SectionHomeTeaserHeroRecord: SingleHeroTeaser,
			SectionHomeTeaserThreeColumnsTradingviewRecord:
				ThreeColumnsTradingView,
			SectionHomeTeaserQuartetRecord: TeaserQuartet,
			SectionHomeTeaserSingleRecord: SingleTeaser,
			SectionHomeTeaserTwoByTwoRecord: TwoByTwoTextOnlyTeasers,
		};

		const teaserSliceSizes = layoutRaw.map((layout): number => {
			if (!(layout.__typename in teasersPerTeaserLayout)) {
				return 0;
			}

			return teasersPerTeaserLayout[
				layout.__typename as keyof typeof teasersPerTeaserLayout
			];
		});

		const teaserSlices = sliceArray(teasers, ...teaserSliceSizes);

		return layoutRaw.map((section, i) => {
			if (!teaserSectionList.includes(section.__typename)) {
				return {
					...section,
					teasers: [],
					component: null,
				};
			}

			return {
				...section,
				teasers: teaserSlices[i] ?? [],
				component:
					teaserComponents[
						section.__typename as keyof typeof teaserComponents
					],
			};
		});
	}, [layoutRaw, teasers]);
};
