import React from "react";
import { chakra, ChakraProps, useMultiStyleConfig } from "@chakra-ui/react";
import { Hx } from "../headings";

export const LayeredBorderDivider: React.FC<
	ChakraProps & { title?: string }
> = ({ title, ...props }) => {
	const styles = useMultiStyleConfig("LayeredBorderDivider", {});

	return (
		<>
			<chakra.div __css={styles.divider} {...props}>
				<chakra.div __css={styles.miniBorder} />
			</chakra.div>
			{title && (
				<Hx size="landingPageDividerHeading" sx={styles.title}>
					{title}
				</Hx>
			)}
		</>
	);
};
