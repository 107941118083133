import {
	chakra,
	ChakraProps,
	Divider,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import {
	ResponsiveImage,
	SectionHomeAdvantageRecord,
} from "../__generated__/types/graphql-codegen";
import {
	AdvantageItem,
	AdvantageItemTitle,
	Advantages,
} from "./Advantages/Advantages";
import { CMSImage } from "./CMSImage";
import { Hx } from "./headings";
import {
	NewsletterDescription,
	NewsletterForm,
	NewsletterButton,
	NewsletterContentWrapper,
	NewsletterDisclaimer,
	NewsletterHeader,
	NewsletterInput,
	NewsletterContentConfig,
} from "./Newsletter/NewsletterForm";

export type BriefingSectionProps = {
	newsletter?: NewsletterContentConfig;
	heading?: string;
	content?: string;
	decorativeImage?: ResponsiveImage;
	advantages?: Array<
		Pick<SectionHomeAdvantageRecord, "title" | "content" | "id">
	>;
};

const Content: ReactFC<
	{
		content: BriefingSectionProps["content"];
		advantages: BriefingSectionProps["advantages"];
	} & ChakraProps
> = ({ content, advantages, ...rest }) => {
	const styles = useMultiStyleConfig("BriefingSection");

	return (
		<chakra.div __css={styles.contentBox} {...rest}>
			<chakra.div
				__css={styles.content}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{ __html: content ?? "" }}
			/>
			<Divider sx={styles.divider} borderColor="base.gray" />
			{advantages && (
				<Advantages variant="briefingSection">
					{advantages.map((adv) => (
						<AdvantageItem key={adv.id}>
							<AdvantageItemTitle>{adv.title}</AdvantageItemTitle>
						</AdvantageItem>
					))}
				</Advantages>
			)}
		</chakra.div>
	);
};

export const BriefingSection: ReactFC<BriefingSectionProps> = ({
	heading,
	content,
	decorativeImage,
	advantages,
	newsletter,
}) => {
	const styles = useMultiStyleConfig("BriefingSection");

	if (!heading || !content || !decorativeImage) {
		return null;
	}

	return (
		<chakra.div __css={styles.sectionWrapper}>
			<chakra.div __css={styles.top}>
				<chakra.div __css={styles.imageContainer}>
					<CMSImage
						responsiveImage={decorativeImage}
						alt=""
						pictureStyle={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				</chakra.div>
				<Hx size="h1-light" sx={styles.title}>
					{heading}
				</Hx>
			</chakra.div>
			<Content advantages={advantages} content={content} />
			<chakra.div __css={styles.newsletterBox}>
				<NewsletterForm showHeader={true} variant="briefingSection">
					<NewsletterContentWrapper>
						<NewsletterHeader>{newsletter?.title}</NewsletterHeader>
						<NewsletterDescription>
							{newsletter?.description && (
								<div
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{
										__html: newsletter.description,
									}}
								/>
							)}
						</NewsletterDescription>
					</NewsletterContentWrapper>
					<NewsletterInput width={{ base: "100%", md: "75%" }} />
					<NewsletterDisclaimer
						disclaimerText={newsletter?.disclaimer ?? undefined}
						requireConsent={true}
						isCentered={false}
					/>
					<NewsletterButton
						buttonText={newsletter?.buttonLabel ?? undefined}
						buttonProps={{
							width: { base: "100%", md: "auto" },
							px: 16,
						}}
					/>
				</NewsletterForm>
			</chakra.div>
		</chakra.div>
	);
};
