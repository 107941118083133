import {
	ChakraProps,
	Container,
	createStylesContext,
	GridItem,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { GridNewsletterForm } from "../../../components/Newsletter/GridNewsletterForm";
import { TeaserData } from "../../../components/teasers/utils";
import { CrossPromoData } from "../../../utils/types";
import { Layout } from "./helpers";
import { HomePageSectionRenderer } from "./HomePageSectionRenderer";

export type HomePageProps = {
	teasers: Array<TeaserData>;
	crossPromoData?: CrossPromoData;
	layout: Array<Layout>;
};

const [HomeSectionsStylesProvider] = createStylesContext("HomeSections");

export const NewsletterSection: ReactFC<ChakraProps> = (props) => {
	return (
		<GridItem colSpan={12} {...props}>
			<GridNewsletterForm variant="eyeCatcher" />
		</GridItem>
	);
};

export const HomePage: ReactFC<HomePageProps> = ({
	teasers,
	crossPromoData, // TODO: will we reuse this?
	layout,
}) => {
	const allStyles = useMultiStyleConfig("HomeSections", {});

	return (
		<HomeSectionsStylesProvider value={allStyles}>
			<Container
				data-cy="home-content"
				data-datocms-noindex
				sx={allStyles.outerWrapper}
				className="inner-content-section"
			>
				<HomePageSectionRenderer layout={layout} teasers={teasers} />
			</Container>
		</HomeSectionsStylesProvider>
	);
};
