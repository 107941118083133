import React from "react";
import {
	NewsletterForm,
	NewsletterDescription,
	NewsletterInput,
	NewsletterDisclaimer,
	NewsletterButton,
	NewsletterContentConfig,
} from "./NewsletterForm";

type HomeNewsletterSectionProps = NewsletterContentConfig;

export const HomeNewsletterSection: React.FC<HomeNewsletterSectionProps> = ({
	description,
	disclaimer,
	buttonLabel,
	title: _,
	...rest
}) => {
	return (
		<NewsletterForm showHeader={false} variant="home" {...rest}>
			<NewsletterDescription>
				{description && (
					// eslint-disable-next-line react/no-danger
					<div dangerouslySetInnerHTML={{ __html: description }} />
				)}
			</NewsletterDescription>
			<NewsletterInput width={{ base: "100%", md: "75%" }} />
			<NewsletterDisclaimer
				disclaimerText={disclaimer ?? undefined}
				requireConsent={true}
				pb={7}
				isCentered={false}
			/>
			<NewsletterButton
				buttonText={buttonLabel ?? "Kostenfrei Abonnieren"}
				maxWidth={{ base: "100%", md: "xs" }}
				pb={7}
			/>
		</NewsletterForm>
	);
};
