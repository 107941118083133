import {
	Teaser,
	TeaserBody,
	TeaserCategory,
	TeaserDate,
	TeaserExcerpt,
	TeaserTitle,
} from "./parts/Teaser";
import { TeaserImage } from "./parts/TeaserImage";
import { findImageData, TeaserData } from "./utils";
import { useRewriteContext } from "../../context/RewriteContext";
import { useTranslatedString } from "../../i18n/hooks";
import { TrackingContextProvider } from "../../lib/gtm/TrackingContext";

export type SingleTeaserProps = { teasers: Array<TeaserData> };

const useExternalArticle = (teaser: TeaserData | undefined) => {
	const brandSlug = teaser?.brandSlug;

	const ctx = useRewriteContext();

	return {
		isCrossPromo: brandSlug !== undefined && brandSlug !== ctx.slug,
		locale: ctx.locale,
		externalBrandLabel: teaser?.brandLabel,
		brandSlug,
	};
};

export const SingleTeaser: ReactFC<SingleTeaserProps> = ({ teasers }) => {
	const t = useTranslatedString();

	const teaser = teasers[0];
	const { isCrossPromo, brandSlug, externalBrandLabel } =
		useExternalArticle(teaser);

	if (!teaser) {
		return null;
	}

	const {
		urlParams,
		title,
		category,
		teaserText,
		image,
		company,
		highlighted,
		trackingType,
		publicationDate,
	} = teaser;

	const thisImage = findImageData(image);
	const alt = thisImage?.alt ?? thisImage?.title;

	return (
		<TrackingContextProvider
			value={{
				articleImage: {
					src: thisImage?.src ?? undefined,
					desc: alt ?? thisImage?.src?.split("/").pop() ?? undefined,
				},
				article: {
					_firstPublishedAt: urlParams._firstPublishedAt,
					company,
					title,
				},
			}}
		>
			<Teaser
				key={urlParams.slug}
				urlParams={{ ...urlParams, targetBrandSlug: brandSlug }}
				highlighted={highlighted}
				company={company}
				brand={brandSlug}
				aria-label={`${t("article", { count: 1 })}: ${title}`}
				trackingType={trackingType}
			>
				<TeaserImage
					alt={alt ?? title}
					wkn={company.wkn}
					imageData={image}
				/>
				<TeaserBody>
					<TeaserCategory>
						{isCrossPromo ? (
							<span>{externalBrandLabel ?? <>&nbsp;</>}</span>
						) : (
							// We want to keep the tag for spacing if no category is set */}
							<span>{category ?? <>&nbsp;</>}</span>
						)}
					</TeaserCategory>
					<TeaserTitle noOfLines={{ base: 2, lg: 999 }}>
						{title}
					</TeaserTitle>
					{publicationDate && <TeaserDate date={publicationDate} />}
					<TeaserExcerpt
						noOfLines={3}
						firstPublishedAt={urlParams._firstPublishedAt}
					>
						{teaserText}
					</TeaserExcerpt>
				</TeaserBody>
			</Teaser>
		</TrackingContextProvider>
	);
};
