import {
	Input,
	InputGroup,
	InputLeftElement,
	InputProps,
} from "@chakra-ui/react";
import React from "react";

export type TextInputProps = { icon?: React.ReactElement } & InputProps;

export const TextInput: ReactFC<TextInputProps> = ({ icon, ...rest }) => {
	return (
		<InputGroup>
			{icon && (
				<InputLeftElement pointerEvents="none" height="100%">
					{icon}
				</InputLeftElement>
			)}
			<Input
				errorBorderColor="base.error"
				focusBorderColor="base.focus"
				{...rest}
			/>
		</InputGroup>
	);
};
