import {
	chakra,
	ChakraProps,
	Divider,
	GridItem,
	VStack,
} from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { Grid } from "../Grid/Grid";
import { SingleOutlinedTeaser } from "./SingleOutlinedTeaser";
import { TextOnlyTeaser } from "./TextOnlyTeaser";
import { TeaserData } from "./utils";

const TradingViewStockMarket = dynamic<ChakraProps>(
	async () =>
		import("../tradingViewWidgets").then(
			(mod) => mod.TradingViewStockMarket,
		),
	{ ssr: false },
);

export const ThreeColumnsTradingView: ReactFC<
	ChakraProps & {
		teasers: Array<TeaserData>;
	}
> = ({ teasers, ...rest }) => {
	return (
		<chakra.div {...rest}>
			<Grid rowGap={8} templateColumns="repeat(12, 1fr)">
				{teasers[0] && (
					<GridItem
						colSpan={{
							base: 12,
							lg: 4,
							xl: 3,
						}}
					>
						<SingleOutlinedTeaser teaser={teasers[0]} />
					</GridItem>
				)}
				<GridItem
					colSpan={{
						base: 12,
						lg: 8,
						xl: 6,
					}}
				>
					<VStack spacing={8} divider={<Divider />}>
						{teasers.slice(1).map((teaser) => (
							<TextOnlyTeaser
								teaser={teaser}
								key={teaser.urlParams.slug}
							/>
						))}
					</VStack>
				</GridItem>
				<GridItem
					colSpan={{
						base: 12,
						lg: 12,
						xl: 3,
					}}
				>
					<TradingViewStockMarket />
				</GridItem>
			</Grid>
		</chakra.div>
	);
};
