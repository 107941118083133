import React from "react";
import {
	useMultiStyleConfig,
	Box,
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	VStack,
} from "@chakra-ui/react";
import { useTranslatedString } from "../../i18n/hooks";
import { Link } from "../Link/Link";
import { useRewriteContext } from "../../context/RewriteContext";

export const NewsletterModal: ReactFC<{
	isOpen: boolean;
	onClose: () => void;
}> = ({ isOpen, onClose }) => {
	const styles = useMultiStyleConfig("NewsletterParts", {});
	const t = useTranslatedString();

	const { locale: currentLocale } = useRewriteContext();

	return (
		<Modal variant="overlay" isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader />
				<ModalCloseButton />
				<ModalBody>
					<VStack sx={styles.modalContent}>
						<Box __css={styles.modalTitle}>
							{t("newsletterThanks")}
						</Box>
						<Box __css={styles.modalDescription}>
							{t("newsletterPleaseConfirm")}
						</Box>
						<Box __css={styles.modalDisclaimer}>
							{t("newsletterNoEmail", {
								policy: (
									<Link
										href={
											currentLocale === "de"
												? "/datenschutz"
												: "/privacy-notice"
										}
									>{`${t("privacyPolicy")}`}</Link>
								),
							})}
						</Box>
						<Flex sx={styles.modalButtonWrapper}>
							<Button
								variant="secondary"
								width="100%"
								onClick={onClose}
							>
								{`${t("close")}`}
							</Button>
							<Link
								href="/"
								sx={styles.newsletterButtonLink}
								width="100%"
							>
								<Button width="100%">
									{t("backToHomePage")}
								</Button>
							</Link>
						</Flex>
					</VStack>
					<Box __css={styles.modalDisclaimer}>
						<Link href="/">{`${t("legalNotice")}`}</Link>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
