import { chakra, ChakraProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { Hx } from "../headings";

export const HomePageHeader: ReactFC<ChakraProps> = ({ children, ...rest }) => {
	const styles = useMultiStyleConfig("HomePageHeader", {});

	return (
		<chakra.div {...rest} __css={styles.container}>
			<Hx sx={styles.heading}>{children}</Hx>
		</chakra.div>
	);
};
