import {
	chakra,
	ChakraProps,
	Stack,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useRewriteContext } from "../../context/RewriteContext";
import { useTranslatedString } from "../../i18n/hooks";
import { truthy } from "../../utils/misc";
import { Article } from "../../utils/types";
import { EvenGrid } from "../Grid/Grid";
import { Hx } from "../headings";
import { LayeredBorderDivider } from "../LayeredBorderDivider/LayeredBorderDivider";
import { SingleTeaser } from "./SingleTeaser";
import { articleDataToTeaserData, TeaserData } from "./utils";

export const TeaserQuartet: ReactFC<
	ChakraProps & {
		teasers: Array<TeaserData>;
	}
> = ({ teasers, ...rest }) => {
	return (
		<chakra.div {...rest}>
			<EvenGrid w="100%">
				{teasers
					.filter(truthy)
					.filter((t) => t.urlParams.slug)
					.slice(0, 4)
					.map((teaser) => (
						<SingleTeaser
							key={teaser.urlParams.slug}
							teasers={[teaser]}
						/>
					))}
			</EvenGrid>
		</chakra.div>
	);
};

export const AdditionalTeaserQuartet: ReactFC<{
	title?: string;
	teasers: Array<TeaserData>;
}> = ({ teasers, title, ...rest }) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("PopularArticles", {});

	if (!teasers.length) {
		return null;
	}

	return (
		<chakra.div __css={styles.container} {...rest}>
			<LayeredBorderDivider />
			<Hx sx={styles.title} size="h3-light">
				{title ?? t("popularArticles")}
			</Hx>
			<TeaserQuartet teasers={teasers} />
		</chakra.div>
	);
};

type PromotedArticleQuartetProps = {
	promotedArticles: Array<Article>;
} & ChakraProps;

export const ArticleQuartetSection: React.FC<PromotedArticleQuartetProps> = ({
	promotedArticles: _promotedArticles,
	...rest
}) => {
	const t = useTranslatedString();
	const { pageType } = useRewriteContext();
	const promotedArticles = useMemo(() => {
		return _promotedArticles.map(articleDataToTeaserData).filter(truthy);
	}, [_promotedArticles]);

	if (!promotedArticles.length) {
		return null;
	}

	return (
		<chakra.div {...rest}>
			<Stack spacing={3} pb={8}>
				{pageType === "landingPage" ? (
					<LayeredBorderDivider
						title={t("relevantArticlesToTopic")}
					/>
				) : (
					<Hx size="h3-light">{t("currentArticles")}</Hx>
				)}
			</Stack>
			<TeaserQuartet teasers={promotedArticles} />
		</chakra.div>
	);
};
