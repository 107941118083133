import { useMemo, useContext, createContext } from "react";
import {
	LandingPage,
	MakePropsNonNullable,
	PressReleases,
	WalledCompany,
} from "../utils/types";
import {
	GetLandingPageBySlugQuery,
	GetPressReleasesByCompanyIdQuery,
} from "../__generated__/types/graphql-codegen";

export type LandingPageContextType = MakePropsNonNullable<LandingPage> & {
	pressReleases?: PressReleases;
	walledCompany?: WalledCompany;
};

const LandingPageContext = createContext<LandingPageContextType | undefined>(
	undefined,
);

export const useLandingPageContext = () => {
	const ctx = useContext(LandingPageContext);

	if (!ctx) {
		throw new Error("LandingPageContext used outside of its provider");
	}

	return ctx;
};

export const useLandingPageContextOrUndefined = () =>
	useContext(LandingPageContext);

type UnnormalizedData = {
	landingPage: GetLandingPageBySlugQuery["landingPage"];
	pressReleases?: GetPressReleasesByCompanyIdQuery["allPressReleases"];
	walledCompany?: NonNullable<
		GetLandingPageBySlugQuery["landingPage"]
	>["company"];
};

const normalizeLandingPageData = ({
	landingPage,
	pressReleases,
	walledCompany,
}: UnnormalizedData): LandingPageContextType | undefined => {
	if (!landingPage) {
		return undefined;
	}

	return {
		...landingPage,
		sectionsLink: landingPage.sectionsLink ?? undefined,
		title: landingPage.title ?? undefined,
		subline: landingPage.subline ?? undefined,
		keyPointsTitle: landingPage.keyPointsTitle ?? undefined,
		keyPointsContent: landingPage.keyPointsContent ?? undefined,
		company: landingPage.company ?? undefined,
		companyWidgetView: landingPage.companyWidgetView ?? undefined,
		image: landingPage.image ?? undefined,
		brand: landingPage.brand ?? undefined,
		noIndex: landingPage.noIndex ?? undefined,
		showRiskNotice: landingPage.showRiskNotice ?? false,
		companyWidgetViewMobileMenu:
			landingPage.companyWidgetViewMobileMenu ?? undefined,
		isWalled: landingPage.isWalled ?? false,
		pressReleases: pressReleases ?? undefined,
		walledCompany: walledCompany ?? undefined,
		keyPointsEnabled: landingPage.keyPointsEnabled ?? undefined,
	};
};

export const LandingPageContextProvider: ReactFC<UnnormalizedData> = ({
	children,
	...params
}) => {
	const value = useMemo(() => {
		return normalizeLandingPageData(params);
	}, [params]);

	return (
		<LandingPageContext.Provider value={value}>
			{children}
		</LandingPageContext.Provider>
	);
};
