import React, {
	ChangeEventHandler,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import {
	chakra,
	Button,
	Checkbox,
	useMultiStyleConfig,
	createStylesContext,
	useDisclosure,
	Stack,
	ChakraProps,
	ButtonProps as ChakraButtonProps,
	InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import { FauxHeading } from "../headings";
import { TextInput } from "../Input/TextInput";
import { useTranslatedString } from "../../i18n/hooks";
import { useNewsletterSignup } from "../../lib/useNewsletterSignup";
import { useRewriteContext } from "../../context/RewriteContext";
import { NewsletterModal } from "./NewsletterModal";
import { useGTMTrackNewsletterSignup } from "../../lib/gtm/dataLayer";
import { isEmptyChildren } from "../../lib/utils";
import { isSSR, truthy } from "../../utils/misc";
import { useCompanyContext } from "../../context/CompanyContext";
import { useLandingPageContextOrUndefined } from "../../context/LandingPageContext";

export type NewsletterContentConfig = {
	__typename?: string | null;
	title?: string | null;
	description?: string | null;
	disclaimer?: string | null;
	buttonLabel?: string | null;
};

type InputProps = {
	onChange: ChangeEventHandler<HTMLInputElement>;
	value: string;
	isInvalid: boolean;
};

type ButtonProps = {
	isLoading: boolean;
};

const [StylesProvider, useNewsletterFormStyles] =
	createStylesContext("NewsletterForm");

const NewsletterFormContext = React.createContext<
	(InputProps & ButtonProps) | undefined
>(undefined);

const useNewsletterFormContext = () => {
	const ctx = useContext(NewsletterFormContext);

	if (!ctx) {
		throw new Error("NewsletterFormContext not found");
	}

	return ctx;
};

export const NewsletterHeader: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useNewsletterFormStyles();
	const t = useTranslatedString();

	return (
		<FauxHeading
			data-cy="newsletter-headline"
			size="h3"
			sx={styles.title}
			{...rest}
		>
			{isEmptyChildren(children) ? t("newsletterCTAHeader") : children}
		</FauxHeading>
	);
};

export const NewsletterDescription: ReactFC<ChakraProps> = ({
	children,
	...rest
}) => {
	const styles = useNewsletterFormStyles();

	const t = useTranslatedString();

	return (
		<chakra.div
			__css={styles.description}
			className="newsletter-description"
			{...rest}
		>
			{isEmptyChildren(children) ? t("newsletterCTAText") : children}
		</chakra.div>
	);
};

export const NewsletterInput: ReactFC<ChakraInputProps> = (props) => {
	const t = useTranslatedString();
	const { onChange, isInvalid, value } = useNewsletterFormContext();

	return (
		<>
			<TextInput
				placeholder={t("emailPlaceholder")}
				variant="flushed"
				type="email"
				onChange={onChange}
				value={value}
				isInvalid={isInvalid}
				isRequired
				{...props}
			/>
			{isInvalid && (
				<chakra.p paddingTop="2" color="base.error">
					{`${t("defaultErrorMessage")}`}
				</chakra.p>
			)}
		</>
	);
};

export const NewsletterButton: ReactFC<
	{
		buttonText?: string;
		buttonProps?: ChakraButtonProps;
	} & ChakraProps
> = ({ buttonText, buttonProps, ...rest }) => {
	const styles = useNewsletterFormStyles();
	const { isLoading } = useNewsletterFormContext();
	const t = useTranslatedString();

	return (
		<chakra.div __css={styles.buttonAndDisclaimer} {...rest}>
			<chakra.div __css={styles.buttonWrapper}>
				<Button
					type="submit"
					isLoading={isLoading}
					width="full"
					{...buttonProps}
				>
					{buttonText ?? t("newsletterCTAButton")}
				</Button>
			</chakra.div>
		</chakra.div>
	);
};

export const NewsletterDisclaimer: ReactFC<
	{
		disclaimerText?: string;
		requireConsent?: boolean;
		isCentered?: boolean;
	} & ChakraProps
> = ({
	disclaimerText,
	requireConsent = false,
	isCentered = true,
	...rest
}) => {
	const styles = useNewsletterFormStyles();
	const t = useTranslatedString();

	return (
		<chakra.div
			__css={styles.disclaimer}
			textAlign={isCentered ? "center" : "left"}
			{...rest}
		>
			{requireConsent ? (
				<Checkbox isRequired={true} defaultChecked={false}>
					<chakra.span
						__css={styles.disclaimer}
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html:
								disclaimerText && disclaimerText.length > 0
									? disclaimerText
									: t("newsletterCTADisclaimer"),
						}}
					/>
				</Checkbox>
			) : (
				<chakra.p __css={styles.disclaimer}>
					{truthy(disclaimerText)
						? disclaimerText
						: t("newsletterCTADisclaimer")}
				</chakra.p>
			)}
		</chakra.div>
	);
};

export const NewsletterForm: ReactFC<
	{
		variant?: string;
		showHeader?: boolean;
	} & React.HTMLAttributes<HTMLFormElement>
> = ({ variant, showHeader = true, children, ...rest }) => {
	const [value, setValue] = useState("");
	const [isInvalid, setInvalid] = useState(false);
	const [pageData, setPageData] = useState<{
		pageTitle: string | undefined;
		pageUrl: string | undefined;
	}>({
		pageTitle: undefined,
		pageUrl: undefined,
	});

	const ctx = useCompanyContext();
	const lpCtx = useLandingPageContextOrUndefined();

	const styles = useMultiStyleConfig("NewsletterForm", { variant });
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { slug: brand, activeCampaignTag } = useRewriteContext();
	const trackSignup = useGTMTrackNewsletterSignup();

	useEffect(() => {
		if (!isSSR && typeof document !== "undefined") {
			setPageData({
				pageTitle: document.title,
				pageUrl: window.location.href,
			});
		}
	}, []);

	const { isLoading, mutate } = useNewsletterSignup(
		{
			email: value,
			brand,
			company: lpCtx?.walledCompany?.name ?? ctx?.company.name,
			sector: lpCtx?.walledCompany?.industry ?? ctx?.company.industry,
			price: lpCtx?.walledCompany?.price ?? ctx?.company.price,
			...pageData,
		},
		activeCampaignTag,
		{
			onSuccess: () => {
				onOpen();
				setValue("");
				setInvalid(false);
				trackSignup();
			},
			onError: () => {
				setInvalid(true);
			},
		},
	);

	const handleSubmit = useCallback(
		(event: any) => {
			event.preventDefault();
			mutate();
		},
		[mutate],
	);

	const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		setValue(event.target.value);
	};

	return (
		<NewsletterFormContext.Provider
			value={{ onChange: handleChange, value, isInvalid, isLoading }}
		>
			<StylesProvider value={styles}>
				<chakra.div __css={styles.container}>
					<chakra.form
						__css={styles.newsletterWrapper}
						onSubmit={handleSubmit}
						{...rest}
					>
						<Stack spacing={7}>{children}</Stack>
					</chakra.form>
				</chakra.div>
				<NewsletterModal isOpen={isOpen} onClose={onClose} />
			</StylesProvider>
		</NewsletterFormContext.Provider>
	);
};

export const NewsletterContentWrapper: ReactFC = ({ children }) => {
	const styles = useNewsletterFormStyles();

	return (
		<chakra.div __css={styles.newsletterContentWrapper}>
			{children}
		</chakra.div>
	);
};
