import React from "react";
import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import {
	NewsletterForm,
	NewsletterDescription,
	NewsletterInput,
	NewsletterButton,
	NewsletterDisclaimer,
	NewsletterHeader,
} from "./NewsletterForm";

// For now since there is only one specific usage of a newsletter grid,
// this component is somewhat strict and the variant is actually passed to NewsletterForm.
export const GridNewsletterForm: ReactFC<{ variant?: string }> = ({
	variant,
}) => {
	const styles = useMultiStyleConfig("GridNewsletterForm", {});

	return (
		<>
			<NewsletterForm variant={variant}>
				<chakra.div __css={styles.gridWrapper}>
					<chakra.div __css={styles.left}>
						<NewsletterHeader />
					</chakra.div>

					<chakra.div __css={styles.main}>
						<NewsletterDescription pb={6} />
						<NewsletterInput />
					</chakra.div>

					<chakra.div __css={styles.right}>
						<NewsletterButton pb={3} />
						<NewsletterDisclaimer />
					</chakra.div>
				</chakra.div>
			</NewsletterForm>
		</>
	);
};
