import {
	chakra,
	createStylesContext,
	List,
	ListItem,
	useMultiStyleConfig,
	useToken,
} from "@chakra-ui/react";
import React from "react";
import { CheckCirlceFilled, Growth, Ratings, Timing } from "../../icons";
import { isDarkColor } from "../../utils/misc";
import { HeadingLevelBoundary, Hx } from "../headings";

export type AdvantageIcon = "none" | "growth" | "ratings" | "timing";

const [StylesProvider, useAdvantagesStyles] = createStylesContext("Advantages");

const AdvantageItemIcon: React.FC<{ icon?: string }> = ({ icon }) => {
	const sharedIconProps = {
		width: 8,
		height: 8,
	};

	// eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
	switch (icon) {
		case "growth": {
			return <Growth {...sharedIconProps} />;
		}
		case "ratings": {
			return <Ratings {...sharedIconProps} />;
		}
		case "timing": {
			return <Timing {...sharedIconProps} />;
		}
	}

	return <CheckCirlceFilled {...sharedIconProps} />;
};

export const AdvantageItemTitle: ReactFC<{ icon?: string }> = ({
	icon = "none",
	children,
}) => {
	const styles = useAdvantagesStyles();

	return (
		<chakra.div __css={styles.item}>
			<AdvantageItemIcon icon={icon} />
			<Hx sx={styles.itemTitle}>{children}</Hx>
		</chakra.div>
	);
};

export const AdvantageItemContent: ReactFC<{ html: string }> = ({
	html: __html,
}) => {
	const styles = useAdvantagesStyles();

	return (
		<chakra.div
			__css={styles.itemContent}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{ __html }}
		/>
	);
};

export const AdvantageItem: ReactFC = ({ children, ...rest }) => {
	return <ListItem {...rest}>{children}</ListItem>;
};

type AdvantagesProps = {
	title?: string;
	variant?: string;
	className?: string;
};

export const AdvantageTitle: ReactFC<{ color?: string; title: string }> = ({
	title,
	color,
	...rest
}) => {
	const styles = useAdvantagesStyles();

	return (
		<Hx color={color} sx={styles.title} {...rest}>
			{title}
		</Hx>
	);
};

export const Advantages: ReactFC<AdvantagesProps> = ({
	title,
	variant,
	children,
	...rest
}) => {
	const styles = useMultiStyleConfig("Advantages", { variant });
	const [color] = useToken("colors", ["base.advantagesBackground"]);

	return (
		<StylesProvider value={styles}>
			<HeadingLevelBoundary>
				<chakra.div
					{...rest}
					backgroundColor={color}
					color={isDarkColor(color) ? "base.white" : "base.black"}
					__css={styles.container}
				>
					{title && (
						<AdvantageTitle
							title={title}
							color={
								isDarkColor(color)
									? "base.gray"
									: "base.darkGray"
							}
						/>
					)}
					<List sx={styles.list}>{children}</List>
				</chakra.div>
			</HeadingLevelBoundary>
		</StylesProvider>
	);
};
