import { ChakraProps } from "@chakra-ui/react";
import { useTranslatedString } from "../../i18n/hooks";
import { TrackingContextProvider } from "../../lib/gtm/TrackingContext";
import {
	Teaser,
	TeaserBody,
	TeaserCategory,
	TeaserDate,
	TeaserExcerpt,
	TeaserTitle,
} from "./parts/Teaser";
import { TeaserImage } from "./parts/TeaserImage";
import { findImageData, TeaserData } from "./utils";

export const SingleHeroTeaser: ReactFC<
	ChakraProps & {
		teasers: Array<TeaserData>;
	}
> = ({ teasers, ...rest }) => {
	const t = useTranslatedString();
	const teaser = teasers[0];

	if (!teaser) {
		return null;
	}

	const {
		urlParams,
		category,
		title,
		teaserText,
		company,
		image,
		publicationDate,
	} = teaser;
	const thisImage = findImageData(image);
	const alt = thisImage?.alt ?? thisImage?.title;

	return (
		<TrackingContextProvider
			value={{
				articleImage: {
					src: thisImage?.src ?? undefined,
					desc: alt ?? thisImage?.src?.split("/").pop() ?? undefined,
				},
				article: {
					_firstPublishedAt: urlParams._firstPublishedAt,
					company,
					title,
				},
			}}
		>
			<Teaser
				variant="hero"
				urlParams={urlParams}
				aria-label={`${t("article", { count: 1 })}: ${title}`}
				company={company}
				pb={{ base: 0, lg: 8 }}
				{...rest}
			>
				<TeaserBody>
					<TeaserCategory>
						{/* We want to keep the tag for spacing if no category is set */}
						{category ?? <>&nbsp;</>}
					</TeaserCategory>
					<TeaserTitle>{title}</TeaserTitle>
					{publicationDate && <TeaserDate date={publicationDate} />}
					<TeaserExcerpt
						firstPublishedAt={urlParams._firstPublishedAt}
					>
						{teaserText}
					</TeaserExcerpt>
				</TeaserBody>
				<TeaserImage
					alt={alt ?? title}
					wkn={company.wkn}
					imageData={image}
				/>
			</Teaser>
		</TrackingContextProvider>
	);
};
